<template lang="pug">
FormKit(
  v-bind='$attrs'
  :id='uniqueId'
  decorator-class='cursor-pointer'
  input-class='$reset p-3 w-full max-w-full border rounded absolute w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer'
  type='radio'
  :aria-label='label'
  :classes='classes'
  :errors='errors'
  :label='label'
  :name='name'
  :options='options'
  :sections-schema='{ inner: { $el: "span" } }'
  :validation='validation'
  :validation-label='validationLabel'
  @blur='onBlur'
  @focus='onFocus'
)
  //- Label slot that passes the Formkit context to a slot so we can use our own custom labels
  template(#label='context')
    span(class='flex-1 cursor-pointer text-sm text-gray-700 mt-1 select-none')
      slot(name='label' :context='context') {{ context.option.label }}
</template>

<script setup lang="ts">
const emit = defineEmits(['blur', 'focus'])
const uniqueId = useId()
/*
  ===
  FormKit Properties
  ===
  classes: Object
    The classes prop is similar to the section-key class prop except it allows setting classes on all
    sections at the same time.
    See: https://formkit.com/essentials/styling#classes-prop#classes-prop
  ---
  errors: String[]
    Array of strings to show as error messages on this field.
    See: https://formkit.com/essentials/inputs#explicit-errors
  ---
  options: Array/Object
    An object of value/label pairs or an array of strings, or an array of objects that must contain a
    label and value property.
    See: https://formkit.com/inputs/select
  ---
  validation: String
    The validation rules to be applied to the input.
    See: https://formkit.com/essentials/validation
  ---
  validation-label: String
    Determines what label to use in validation error messages, by default it uses the label prop if
    available, otherwise it uses the name prop.
    See: https://formkit.com/essentials/validation#custom-messages
*/

interface Props {
  classes?: {}
  errors?: string[]
  label?: string
  name?: string
  options: string[] | {} | any[]
  validation?: string
  validationLabel?: string
}

withDefaults(defineProps<Props>(), {
  classes: () => {
    return {}
  },
  errors: () => [],
  label: '',
  name: '',
  placeholder: '',
  validation: '',
  validationLabel: '',
})

function onBlur() {
  emit('blur')
}

function onFocus() {
  emit('focus')
}
</script>
